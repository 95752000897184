import { useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { useSystemContext } from '../../Context/SystemContext';
import Grid from '../../components/Grid';
import { Grid5W2H } from '../../components/Grid5W2H';
import { GridAnexos } from '../../components/GridAnexos';
import { GridDiario } from '../../components/GridDiario';

import { CheckBoxInput, DBSelectInput, DateInput, FormInput, GroupInput, Input, SelectInput, TabsInput, TextInput } from '../../components/Input';
import { dateToString } from '../../helper/dates';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

const yup = require('../../interface/yup-br');

export const FormA1 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/formA1"
                perms={'101'}
                title={System.getMenuTitle(101)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const FormA2SGI = () => {
    const System = useSystemContext();
    const [retorno, setRetorno] = useState(null);
    const companyId = System.property.companyId;

    useEffect(() => {
        const fetch = async () => {
            let empresa = await System.methods.getCompany();
            if (empresa.ativaNaoConformidadeSimples === true) {
                setRetorno(<FormA2SGISimplificada />);
            } else {
                setRetorno(<FormA2SGINormal />);
            }
        };
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    return retorno;
};

const FormA2SGISimplificada = () => {
    const System = useSystemContext();
    const gridRef = useRef(null);
    let _crew = [];
    System.apiGet('/crew').then((res) => {
        res.data.map((item) => {
            _crew[`${item._id}`] = item.nome;
            return true;
        });
    });
    const addFooter = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        const url = window.location.href; // Pega a URL corrente
        const hoje = new Date();
        const dia = hoje.getDate().toString().padStart(2, '0');
        const mes = (hoje.getMonth() + 1).toString().padStart(2, '0'); // Janeiro é 0!
        const ano = hoje.getFullYear();

        const dataFormatada = `${dia}/${mes}/${ano}`;

        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);

            const footerText = `Página ${i}/${pageCount} - URL: ${url} - Emitido em ${dataFormatada} por ${System.property.user?.name}`;
            const textSize = doc.getTextDimensions(footerText);
            const pageCenter = doc.internal.pageSize.width / 2;
            const xPosition = pageCenter - textSize.w / 2;

            // Defina a posição do rodapé aqui
            doc.text(footerText, xPosition, doc.internal.pageSize.height - 5);
        }
    };
    const EspelhoPdf = async (item) => {
        // Cria uma instância do objeto jsPDF
        const doc = new jsPDF();

        const response = await System.apiGet('/company/' + System.property.user.companyId);
        const company = response.data;
        const logo = await System.methods.apiGetImg(company.logotipo);

        // Adicionar o quadro com subdivisões
        const quadroWidth = doc.internal.pageSize.width - 20;
        const quadroTopo = 20;
        let top = 18;
        let lines = '';

        // Adiciona o cabeçalho
        function addHeader() {
            doc.addImage(logo, 'JPEG', doc.internal.pageSize.width - 55, 5, 30, 10);
            doc.setFontSize(14);
            doc.text(`Relatório de não conformidade`, 12, 17);
            doc.line(10, 18, doc.internal.pageSize.width - 20, 18);
            doc.setFontSize(9);
        }


        doc.setDrawColor(0);
        doc.setFontSize(9);

        addHeader();

        top = quadroTopo + 1;
        doc.text(`Número`, 12, (top += 5));
        doc.text(`Data`, 35, top);
        doc.text(`Emitente`, 70, top);
        doc.text(`Origem`, doc.internal.pageSize.width - 50, top);
        doc.text(item.numero || '---', 12, (top += 3));
        doc.text(dateToString(item.data) || '---', 35, top);
        doc.text(_crew[item.emitente] || '---', 70, top);
        doc.text(item.origem || '---', doc.internal.pageSize.width - 50, top);
        //
        doc.text(`Descrição resumida:`, 12, (top += 4));
        lines = doc.splitTextToSize(item.descricaoresumida, quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Cliente`, 12, (top += 5));
        doc.text(`Provedor externo`, 90, top);
        doc.text(`Processo`, doc.internal.pageSize.width - 50, top);
        doc.text(`${item.cliente || '---'}`, 12, (top += 3));
        doc.text(`${item.provedorexterno || '---'}`, 90, top);
        doc.text(`${item.processo || '---'}`, doc.internal.pageSize.width - 50, top);
        //
        doc.text(`Descrição completa:`, 12, (top += 5));
        lines = doc.splitTextToSize(item.descricao, quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //////////////////////////////// Disposicao

        doc.setFontSize(14);
        doc.text(`Disposição`, 12, (top += 10));
        doc.line(10, top + 1, doc.internal.pageSize.width - 20, top + 1);
        doc.setFontSize(9);
        //
        doc.text(`Disposição`, 12, (top += 7));
        doc.text(item.disposicao || '---', 12, (top += 3));
        //
        doc.text(`Detalhamento/Operação`, 12, (top += 4));
        lines = doc.splitTextToSize(item.detalhamento || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Responsável`, 12, (top += 4));
        doc.text(`Prazo`, doc.internal.pageSize.width - 60, top);
        doc.text(`Status`, doc.internal.pageSize.width - 40, top);
        doc.text(_crew[item.responsavel] || '---', 12, (top += 3));
        doc.text(dateToString(item.prazodisposicao) || '---', doc.internal.pageSize.width - 60, top);
        doc.text(item.statusdisposicao || '---', doc.internal.pageSize.width - 40, top);
        //
        doc.text(`Ação Corretiva:`, 12, (top += 4));
        doc.text(item.acaocorretiva || '---', 12, (top += 3));
        //////////////////////////////// Analise da causa
        doc.setFontSize(14);
        doc.text(`Análise da Causa`, 12, (top += 15));
        doc.line(10, top + 1, doc.internal.pageSize.width - 20, top + 1);
        doc.setFontSize(9);
        //
        doc.text(`Responsável`, 12, (top += 7));
        doc.text(`Prazo`, doc.internal.pageSize.width - 60, top);
        doc.text(`Status`, doc.internal.pageSize.width - 40, top);
        doc.text(_crew[item.resposavelanalise] || '---', 12, (top += 3));
        doc.text(dateToString(item.prazoanalise) || '', doc.internal.pageSize.width - 60, top);
        doc.text(item.statusanalise || '---', doc.internal.pageSize.width - 40, top);
        //
        doc.text(`Extensão da Ocorrência:`, 12, (top += 5));
        lines = doc.splitTextToSize(item.extensao || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`5 Porquês:`, 12, (top += 5));
        lines = doc.splitTextToSize(item.os5porques || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`6M's`, 12, (top += 5));
        //
        doc.text(`Máquina`, 15, (top += 3));
        lines = doc.splitTextToSize(item.maquina || '---', quadroWidth - 15);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Materiais`, 15, (top += 4));
        lines = doc.splitTextToSize(item.materiais || '---', quadroWidth - 15);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Mão de obra`, 15, (top += 4));
        lines = doc.splitTextToSize(item.maodeobra || '---', quadroWidth - 15);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Meio ambiente`, 15, (top += 4));
        lines = doc.splitTextToSize(item.meioambiente || '---', quadroWidth - 15);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Método`, 15, (top += 4));
        lines = doc.splitTextToSize(item.metodo || '---', quadroWidth - 15);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Medidas`, 15, (top += 4));
        lines = doc.splitTextToSize(item.medidas || '---', quadroWidth - 15);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        // ----------------------------------
        if (top > 250) {
            doc.addPage();
            addHeader();
            top = 20; // Checa final de pagina
        }
        //
        doc.text(`Causa(s) Raiz`, 12, (top += 4));
        lines = doc.splitTextToSize(item.causaraiz || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        // ----------------------------------
        if (top > 250) {
            doc.addPage();
            addHeader();
            top = 20; // Checa final de pagina
        }
        //
        doc.text(`Participantes da análise:`, 12, (top += 4));
        lines = doc.splitTextToSize(item.participanteanalise || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        // ----------------------------------
        if (top > 250) {
            doc.addPage();
            addHeader();
            top = 20; // Checa final de pagina
        }
        //
        doc.text(`Data da análise`, 12, (top += 4));
        doc.text(dateToString(item.dataanalize) || '---', 12, (top += 3));
        //
        doc.text(`${item.alteramapeamento ? '[ X ]' : '[   ]'} Altera mapeamento de processos?`, 12, (top += 5));
        doc.text(`${item.alterariscos ? '[ X ]' : '[   ]'} Altera gestão de riscos e oportunidades?`, 12, (top += 4));
        doc.text(`${item.alteraaspectoimpacto ? '[ X ]' : '[   ]'} Altera gestão de aspectos e impactos ambientais?`, 12, (top += 4));
        doc.text(`${item.alteragestaoriscoasaude ? '[ X ]' : '[   ]'} Altera gestão de riscos à saúde e segurança ocupacional?`, 12, (top += 4));
        doc.text(`${item.alteracomunicacao ? '[ X ]' : '[   ]'} Altera gestão de comunicação?`, 12, (top += 4));
        doc.text(`${item.mudancasgq ? '[ X ]' : '[   ]'} Necessidade de mudança do SGQ?`, 12, (top += 4));
        // ------ Quebra de página
        doc.addPage();
        doc.addImage(logo, 'JPEG', doc.internal.pageSize.width - 55, 5, 30, 10);
        top = 17;
        /////////////////// Ação corretiva
        doc.setFontSize(14);
        doc.text(`Ação corretiva`, 12, top);
        doc.line(10, top + 1, doc.internal.pageSize.width - 20, top + 1);
        doc.setFontSize(9);
        //
        doc.text(`Plano de Execução:`, 12, (top += 4));
        lines = doc.splitTextToSize(item.planoexecucao || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Responsável:`, 12, (top += 5));
        doc.text(`Prazo:`, doc.internal.pageSize.width - 80, top);
        doc.text(`Data:`, doc.internal.pageSize.width - 60, top);
        doc.text(`${_crew[item.responsavelacao] || '---'}`, 12, (top += 3));
        doc.text(dateToString(item.prazoanalise) || '---', doc.internal.pageSize.width - 80, top);
        doc.text(dateToString(item.dataacao) || '---', doc.internal.pageSize.width - 60, top);
        //
        doc.text(`Status ação corretiva:`, 12, (top += 4));
        doc.text(`${item.statusanalise || '---'}`, 12, (top += 3));
        ////////////////////// Verificação
        doc.setFontSize(14);
        doc.text(`Verificação`, 12, (top += 15));
        doc.line(10, top + 4, doc.internal.pageSize.width - 20, top + 3);
        doc.setFontSize(9);
        //
        doc.text(`Verificação do Plano:`, 12, (top += 10));
        lines = doc.splitTextToSize(item.verificacaoplano || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Responsável:`, 12, (top += 4));
        doc.text(`Data:`, doc.internal.pageSize.width - 80, top);
        doc.text(`Prazo para Eficácia:`, doc.internal.pageSize.width - 60, top);
        doc.text(`${_crew[item.resposavelplano] || '---'}`, 12, (top += 3));
        doc.text(dateToString(item.dataplano) || '---', doc.internal.pageSize.width - 80, top);
        doc.text(dateToString(item.prazoplano) || '---', doc.internal.pageSize.width - 60, top);
        //
        doc.text(`Status Eficácia:`, 12, (top += 4));
        doc.text(`${item.statusverificacao || '---'}`, 12, (top += 3));
        /////////////////// Eficácia
        doc.setFontSize(14);
        doc.text(`Eficácia`, 12, (top += 15));
        doc.line(10, top + 4, doc.internal.pageSize.width - 20, top + 3);
        doc.setFontSize(9);
        //
        doc.text(`Eficácia do Plano:`, 12, (top += 10));
        lines = doc.splitTextToSize(item.eficaciaplano || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Responsável:`, 12, (top += 4));
        doc.text(`Data:`, doc.internal.pageSize.width - 60, top);
        doc.text(`${_crew[item.resposaveleficacia] || '---'}`, 12, (top += 3));
        doc.text(dateToString(item.dataeficacia) || '---', doc.internal.pageSize.width - 60, top);
        //

        await System.apiGet(`/method5w2h/formA2SGI-${item._id}`).then((res) => {
            const items = res.data;
            if (items.length > 0) {
                doc.addPage();
                doc.addImage(logo, 'JPEG', doc.internal.pageSize.width - 55, 5, 30, 10);
                top = 17;
                doc.setFontSize(14);
                doc.text(`5W2H`, 12, top);
                doc.line(10, top + 1, doc.internal.pageSize.width - 20, top + 1);
                doc.setFontSize(9);
                let i = 0;
                items.map((item) => {
                    i += 1;
                    if (i > 3) {
                        doc.addPage();
                        doc.addImage(logo, 'JPEG', doc.internal.pageSize.width - 55, 5, 30, 10);
                        top = 17;
                        doc.setFontSize(14);
                        doc.text(`5W2H`, 12, top);
                        doc.line(10, top + 1, doc.internal.pageSize.width - 20, top + 1);
                        doc.setFontSize(9);
                    }
                    doc.text(`O que?`, 12, (top += 5));
                    lines = doc.splitTextToSize(item.what || '---', quadroWidth - 10);
                    lines.forEach((line) => {
                        doc.text(line, 12, (top += 3));
                    });
                    //
                    doc.text(`Por que?`, 12, (top += 5));
                    lines = doc.splitTextToSize(item.why || '---', quadroWidth - 10);
                    lines.forEach((line) => {
                        doc.text(line, 12, (top += 3));
                    });
                    //
                    doc.text(`Quem?`, 12, (top += 5));
                    doc.text(`${_crew[item.who] || '---'}`, 12, (top += 3));
                    //
                    doc.text(`Quando?`, 12, (top += 5));
                    doc.text(`${dateToString(item.when) || '---'}`, 12, (top += 3));
                    //
                    doc.text(`Onde?`, 12, (top += 5));
                    lines = doc.splitTextToSize(item.where || '---', quadroWidth - 10);
                    lines.forEach((line) => {
                        doc.text(line, 12, (top += 3));
                    });
                    //
                    doc.text(`Como?`, 12, (top += 5));
                    lines = doc.splitTextToSize(item.how || '---', quadroWidth - 10);
                    lines.forEach((line) => {
                        doc.text(line, 12, (top += 3));
                    });
                    //
                    doc.text(`Quanto?`, 12, (top += 5));
                    lines = doc.splitTextToSize(item.howmuch || '---', quadroWidth - 10);
                    lines.forEach((line) => {
                        doc.text(line, 12, (top += 3));
                    });
                    //
                    doc.text(`Status?`, 12, (top += 5));
                    doc.text(`${item.status || '---'}`, 12, (top += 3));
                    //
                    doc.text(`Observações`, 12, (top += 5));
                    lines = doc.splitTextToSize(item.observacoes || '---', quadroWidth - 10);
                    lines.forEach((line) => {
                        doc.text(line, 12, (top += 3));
                    });
                    top += 6;
                    doc.line(10, top, doc.internal.pageSize.width - 20, top);
                    top += 6;
                    return true;
                });
            }
        });

        // Adicionar o rodapé
        addFooter(doc);

        // Salve o PDF
        doc.save(`NaoConformidade${item.numero ? '-' + item.numero : ''}.pdf`);
    };
    function Etapa(conclusao) {
        return <div className={`mt-2 h-3 rounded-full ${conclusao === 'Concluído' ? 'bg-green-400' : 'bg-gray-400'}`}></div>;
    }
    function ValidacaoGrid(value) {
        if (value === 'A') {
            return (
                <div className="inline-flex justify-center" title="Aprovado">
                    🟢
                </div>
            );
        } else if (value === 'R') {
            return (
                <div className="inline-flex justify-center" title="Reprovado">
                    🔴
                </div>
            );
        } else {
            return (
                <div className="inline-flex justify-center" title="Pendente">
                    🟡
                </div>
            );
        }
    }
    function isResponsavel(idResponsavel = null) {
        if (System.property.user.level > 1) return true;
        return idResponsavel === System.property.user.crew_id;
    }

    function CallGridSave() {
        gridRef.current.methods.GridSave();
    }

    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/formA2"
                        perms={'102001'}
                        title={System.getMenuTitle(102001)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <Grid
                        url={'/griddocument/gridA2'}
                        perms={'102001'}
                        clearAutoEdit={true}
                        hideIndexColumn={true}
                        disableModal={true}
                        externalRef={gridRef}
                        initialValues={{
                            validacaomaster: 'P',
                        }}
                        onAfterLoadData={(data) => {
                            return data.map((item) => {
                                if (typeof item.statusdisposicao === 'undefined') item.statusdisposicao = '';
                                if (typeof item.statusacao === 'undefined') item.statusacao = '';
                                if (typeof item.statusverificacao === 'undefined') item.statusverificacao = '';

                                return item;
                            });
                        }}
                        onPrepareSaveRecord={(values) => {
                            if (values.validacaomaster !== 'A' && values.validacaomaster !== 'R') {
                                values.validacaomaster = 'P';
                            }

                            return values;
                        }}
                        validationSchema={yup.object().shape({
                            numero: yup.string(),
                            data: yup.date(),
                            emitente: yup.string(),
                            origem: yup.string(),
                            descricaoresumida: yup.string(),
                            cliente: yup.string(),
                            provedorexterno: yup.string(),
                            descricao: yup.string(),
                        })}
                        GridHeaders={[
                            {
                                title: 'Número',
                                field: 'numero',
                                type: 'number',
                                filter: true,
                                sort: true,
                                className: 'w-1/12 text-left pl-1',
                            },
                            {
                                title: 'Data',
                                field: 'data',
                                type: 'date',
                                filter: true,
                                sort: true,
                                className: 'w-1/12 pl-1 text-center',
                            },
                            {
                                title: 'Validação',
                                field: 'validacaomaster',
                                _filter: true,
                                filter: {
                                    type: 'select',
                                    definition: [
                                        { value: 'P', label: '🟡 Pendente' },
                                        { value: 'A', label: '🟢 Aprovado' },
                                        { value: 'R', label: '🔴 Reprovado' },
                                    ],
                                },
                                sort: true,
                                className: 'w-1/12 pl-1 text-center border-l border-gray-300',
                            },
                            {
                                title: 'Descrição',
                                field: 'descricaoresumida',
                                filter: true,
                                _filterTags: [],
                                sort: true,
                                className: 'w-2/12 text-left border-l border-gray-300',
                            },
                            {
                                title: 'Origem',
                                field: 'origem',
                                filter: true,
                                filterTags: [],
                                sort: true,
                                className: 'w-1/12 pl-1 text-left border-l border-gray-300',
                            },
                            {
                                title: 'Processo',
                                field: 'processo',
                                filter: true,
                                sort: true,
                                className: 'w-1/12 pl-1 text-left border-l border-gray-300',
                            },
                            {
                                title: 'Cliente',
                                field: 'cliente',
                                filter: true,
                                sort: true,
                                className: 'w-2/12 pl-1 text-left border-l border-gray-300',
                            },
                            {
                                title: 'Provedor externo',
                                field: 'provedorexterno',
                                filter: true,
                                sort: true,
                                className: 'w-2/12 pl-1 text-left border-l border-gray-300',
                            },
                            {
                                title: 'Disposição',
                                field: 'statusdisposicao',
                                filter: {
                                    type: 'select',
                                    definition: [
                                        { value: 'Pendente', label: 'Pendente' },
                                        { value: 'Concluído', label: 'Concluído' },
                                    ],
                                },
                                sort: true,
                                className: 'w-1/12 pl-1 text-center border-l border-gray-300',
                            },
                            {
                                title: 'Ação corretiva',
                                field: 'statusacao',
                                filter: {
                                    type: 'select',
                                    definition: [
                                        { value: 'Pendente', label: 'Pendente' },
                                        { value: 'Concluído', label: 'Concluído' },
                                    ],
                                },
                                sort: true,
                                className: 'w-1/12 pl-1 text-center border-l border-gray-300',
                            },
                            {
                                title: 'Eficácia',
                                field: 'statusverificacao',
                                filter: {
                                    type: 'select',
                                    definition: [
                                        { value: 'Pendente', label: 'Pendente' },
                                        { value: 'Concluído', label: 'Concluído' },
                                    ],
                                },

                                sort: true,
                                className: 'w-1/12 pl-1 text-center border-l border-gray-300',
                            },
                        ]}
                        LineDataPrint={(data) => {
                            return [
                                data.numero,
                                dateToString(data.data),
                                ValidacaoGrid(data?.validacaomaster),
                                data.descricaoresumida,
                                data.origem,
                                data.processo,
                                data.cliente,
                                data.provedorexterno,
                                data.statusdisposicao,
                                (data.acaocorretiva !== 'Não') ? data.statusacao : 'Não aplicável',
                                (data.acaocorretiva !== 'Não') ? data.statusverificacao : 'Não aplicável',
                            ];
                        }}
                        onBeforeInsertAction={(item) => {
                            const GridData = gridRef.current.methods.GridData();
                            let maiorNumero = GridData.reduce((maior, item) => {
                                const valor = parseInt(item.numero);
                                return valor > maior && Number.isInteger(valor) ? valor : maior;
                            }, 0);
                            maiorNumero += 1;
                            item.numero = maiorNumero.toString();

                            return item;
                        }}
                        onBeforeEditAction={(item, GridData) => {
                            // caso nao seja responsavel de nada, nao permite alterar
                            if (item.validacaomaster === 'A' && System.property.user.level === 1) {
                                const responsaveis = [item.responsavel, item.resposavelanalise, item.responsavelacao, item.resposavelplano, item.resposaveleficacia];
                                if (!responsaveis.includes(System.property.user.id)) {
                                    let gma = gridRef.current.methods.GridModes();
                                    gma.temporary_show = true;
                                    gridRef.current.methods.GridModes(gma);
                                }
                            }

                            if (item.validacaomaster !== 'A' && System.property.user.level === 1) {
                                let gm = gridRef.current.methods.GridModes();
                                gm.temporary_show = true;

                                gridRef.current.methods.GridModes(gm);
                            }
                            return item;
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            const responsaveis = [values.responsavel, values.resposavelanalise, values.responsavelacao, values.resposavelplano, values.resposaveleficacia];
                            let onlyshow = false;
                            if (!responsaveis.includes(System.property.user.id) && System.property.user.level === 1) {
                                onlyshow = true;
                            }
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? (onlyshow ? 'Visualizando' : 'Editar') : 'Novo registro'}</span>
                                    </div>
                                    {!GridModes.insert_mode && (
                                        <div className="flex h-16 mt-2">
                                            <div className="w-3/12 ml-2 mr-2 border-1">
                                                {System.property.user.level > 1 && (
                                                    <div>
                                                        Validação
                                                        <SelectInput
                                                            name="validacaomaster"
                                                            label=""
                                                            className="inline-block"
                                                            listOptions={[
                                                                { value: 'P', option: '🟡 Pendente' },
                                                                { value: 'A', option: '🟢 Aprovado' },
                                                                { value: 'R', option: '🔴 Reprovado' },
                                                            ]}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="w-2/12 ml-2 text-center border-1">Disposição {Etapa(values.statusdisposicao)}</div>
                                            <div className="w-2/12 ml-2 text-center border-1">Análise da Causa {Etapa(values.statusanalise)}</div>
                                            <div className="w-2/12 ml-2 text-center border-1">Ação Corretiva {Etapa(values.statusacao)}</div>
                                            <div className="w-2/12 ml-2 text-center border-1">Verificação {Etapa(values.statusverificacao)}</div>
                                        </div>
                                    )}
                                    {values.validacaomaster === 'R' && (
                                        <div className="flex h-16 mt-2">
                                            <div className="flex w-full p-2 ml-2 mr-2 border border-red-500 border-1">
                                                <span className="flex-none mt-3 ml-2">Considerações</span>
                                                <div className="flex-grow">
                                                    <Input name="validacaomasterconsideracoes" label="" />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div name="home">
                                        <div>
                                            <GroupInput>
                                                <Input name="numero" label="Número" className="w-1/6" />
                                                <DateInput name="data" label="Data" className="inline-block" />
                                                <DBSelectInput name="emitente" label="Emitente" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                                <SelectInput
                                                    name="origem"
                                                    label="Origem"
                                                    className="inline-block"
                                                    listOptions={[
                                                        { value: 'Auditoria interna' },
                                                        { value: 'Auditoria externa' },
                                                        { value: 'Provedor externo' },
                                                        { value: 'Cliente' },
                                                        { value: 'Processo interno' },
                                                        { value: 'Mudança' },
                                                        { value: 'Incidente' },
                                                        { value: 'Acidente' },
                                                        { value: 'Análise crítica da direção' },
                                                        { value: 'Outros' },
                                                    ]}
                                                />
                                            </GroupInput>
                                            <Input name="descricaoresumida" label="Descrição resumida" className="w-full" />
                                            <GroupInput>
                                                <Input name="cliente" label="Cliente" />
                                                <Input name="provedorexterno" label="Provedor externo" />
                                                <Input name="processo" label="Processo" />
                                            </GroupInput>
                                            <TextInput name="descricao" label="Descrição completa" className="w-full" rows="5" />
                                        </div>
                                    </div>
                                    {!GridModes.insert_mode && values.validacaomaster === 'A' && (
                                        <div>
                                            <div name="disposicao" className={`pb-3 ${values.statusdisposicao === 'Concluído' ? 'bg-gray-300' : 'bg-white'}`}>
                                                <div className="w-full h-8 py-1 pl-2 mt-2 bg-amarelo-eq">Disposição</div>
                                                <div className="w-full">
                                                    <SelectInput
                                                        name="disposicao"
                                                        label="Disposição"
                                                        disabled={!isResponsavel(values.responsavel)}
                                                        listOptions={[
                                                            { value: 'Rejeição' },
                                                            { value: 'Retrabalho' },
                                                            { value: 'Reclassificação' },
                                                            { value: 'Concessão' },
                                                            { value: 'Reposição' },
                                                            { value: 'Coleta e reposição' },
                                                            { value: 'Usar como está' },
                                                            { value: 'Outro' },
                                                        ]}
                                                    />
                                                    <TextInput
                                                        name="detalhamento"
                                                        label="Detalhamento/Operação"
                                                        disabled={!isResponsavel(values.responsavel)}
                                                        className="w-full"
                                                        rows="5"
                                                    />
                                                    <GroupInput>
                                                        <DBSelectInput
                                                            name="responsavel"
                                                            label="Responsável"
                                                            disabled={!isResponsavel(values.responsavel)}
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <DateInput name="prazodisposicao" label="Prazo" disabled={!isResponsavel(values.responsavel)} className="w-1/6" />
                                                        <SelectInput
                                                            name="statusdisposicao"
                                                            label="Status"
                                                            disabled={!isResponsavel(values.responsavel)}
                                                            className={'w-44'}
                                                            listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                        />
                                                    </GroupInput>
                                                    <GroupInput>
                                                        <SelectInput
                                                            name="acaocorretiva"
                                                            label="Ação Corretiva"
                                                            disabled={!isResponsavel(values.responsavel)}
                                                            className={'w-3/6'}
                                                            listOptions={[{ value: 'Não' }, { value: 'Sim' }]}
                                                        />
                                                    </GroupInput>
                                                    {values.responsavel === System.property.user.crew_id && System.property.user.level === 1 && (
                                                        <GroupInput>
                                                            <div className="flex justify-end w-full gap-4 pt-4 pb-3 pr-4 mt-4 border-t">
                                                                <button
                                                                    type="submit"
                                                                    className="w-1/5 h-8 rounded-md shadow-md border-1 bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte"
                                                                    onClick={CallGridSave}
                                                                >
                                                                    {GridModes.insert_mode ? 'Salvar' : 'Alterar'}
                                                                </button>
                                                            </div>
                                                        </GroupInput>
                                                    )}
                                                </div>
                                            </div>
                                            <div name="analiseCausa" className={`pb-3 ${values.statusanalise === 'Concluído' ? 'bg-gray-300' : 'bg-white'}`}>
                                                <div className="w-full h-8 py-1 pl-2 mt-1 bg-amarelo-eq">Análise da Causa</div>
                                                <GroupInput>
                                                    <DBSelectInput
                                                        name="resposavelanalise"
                                                        label="Responsável"
                                                        disabled={!isResponsavel(values.resposavelanalise)}
                                                        url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                        displaySelectField="nome"
                                                    />
                                                    <DateInput name="prazoanalise" label="Prazo" className="w-1/6" disabled={!isResponsavel(values.resposavelanalise)} />
                                                    <SelectInput
                                                        name="statusanalise"
                                                        label="Status"
                                                        disabled={!isResponsavel(values.resposavelanalise)}
                                                        className={'w-44'}
                                                        listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                    />
                                                </GroupInput>
                                                <TextInput
                                                    disabled={!isResponsavel(values.resposavelanalise)}
                                                    name="extensao"
                                                    label="Extensão da Ocorrência"
                                                    className="w-full"
                                                    rows="2"
                                                />
                                                <TextInput disabled={!isResponsavel(values.resposavelanalise)} name="os5porques" label="5 Porquês" rows={5} />
                                                <div className="mt-4 mb-2 ml-2">6M's</div>
                                                <Input disabled={!isResponsavel(values.resposavelanalise)} name="maquina" label="Máquina" />
                                                <Input disabled={!isResponsavel(values.resposavelanalise)} name="materiais" label="Materiais" />
                                                <Input disabled={!isResponsavel(values.resposavelanalise)} name="maodeobra" label="Mão de Obra" />
                                                <Input disabled={!isResponsavel(values.resposavelanalise)} name="meioambiente" label="Meio Ambiente" />
                                                <Input disabled={!isResponsavel(values.resposavelanalise)} name="metodo" label="Método" />
                                                <Input disabled={!isResponsavel(values.resposavelanalise)} name="medidas" label="Medidas" />
                                                <TextInput disabled={!isResponsavel(values.resposavelanalise)} name="causaraiz" label="Causa(s) Raiz" rows="2" />
                                                <TextInput
                                                    disabled={!isResponsavel(values.resposavelanalise)}
                                                    name="participanteanalise"
                                                    label="Participantes da análise"
                                                    rows="2"
                                                />
                                                <DateInput disabled={!isResponsavel(values.resposavelanalise)} name="dataanalize" label="Data da análise" />
                                                <CheckBoxInput
                                                    disabled={!isResponsavel(values.resposavelanalise)}
                                                    name="alteramapeamento"
                                                    label="Altera mapeamento de processos?"
                                                />
                                                <CheckBoxInput
                                                    disabled={!isResponsavel(values.resposavelanalise)}
                                                    name="alterariscos"
                                                    label="Altera gestão de riscos e oportunidades?"
                                                />
                                                <CheckBoxInput
                                                    disabled={!isResponsavel(values.resposavelanalise)}
                                                    name="alteraaspectoimpacto"
                                                    label="Altera gestão de aspectos e impactos ambientais?"
                                                />
                                                <CheckBoxInput
                                                    disabled={!isResponsavel(values.resposavelanalise)}
                                                    name="alteragestaoriscoasaude"
                                                    label="Altera gestão de riscos à saúde e segurança ocupacional?"
                                                />
                                                <CheckBoxInput disabled={!isResponsavel(values.resposavelanalise)} name="alteracomunicacao" label="Altera gestão de comunicação?" />
                                                <CheckBoxInput disabled={!isResponsavel(values.resposavelanalise)} name="mudancasgq" label="Necessidade de mudança do SGQ?" />
                                                {values.resposavelanalise === System.property.user.crew_id && System.property.user.level === 1 && (
                                                    <GroupInput>
                                                        <div className="flex justify-end w-full gap-4 pt-4 pb-3 pr-4 mt-4 border-t">
                                                            <button
                                                                type="submit"
                                                                className="w-1/5 h-8 rounded-md shadow-md border-1 bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte"
                                                                onClick={CallGridSave}
                                                            >
                                                                {GridModes.insert_mode ? 'Salvar' : 'Alterar'}
                                                            </button>
                                                        </div>
                                                    </GroupInput>
                                                )}
                                            </div>
                                            <div name="acaoCorretiva" className={`pb-3 ${values.statusacao === 'Concluído' ? 'bg-gray-300' : 'bg-white'}`}>
                                                <div className="w-full h-8 py-1 pl-2 mt-1 bg-amarelo-eq">Ação Corretiva</div>
                                                <TextInput
                                                    disabled={!isResponsavel(values.responsavelacao)}
                                                    name="planoexecucao"
                                                    label="Plano de Execução"
                                                    className="w-full"
                                                    rows={5}
                                                />
                                                <GroupInput>
                                                    <DBSelectInput
                                                        name="responsavelacao"
                                                        label="Responsável"
                                                        disabled={!isResponsavel(values.responsavelacao)}
                                                        url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                        displaySelectField="nome"
                                                    />
                                                    <DateInput disabled={!isResponsavel(values.responsavelacao)} name="prazoacao" label="Prazo" className="inline-block" />
                                                    <DateInput disabled={!isResponsavel(values.responsavelacao)} name="dataacao" label="Data" className="inline-block" />
                                                </GroupInput>
                                                <GroupInput>
                                                    <SelectInput
                                                        name="statusacao"
                                                        label="Status ação corretiva"
                                                        disabled={!isResponsavel(values.responsavelacao)}
                                                        className={'inline-block'}
                                                        listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                    />
                                                </GroupInput>
                                                {values.responsavelacao === System.property.user.crew_id && System.property.user.level === 1 && (
                                                    <GroupInput>
                                                        <div className="flex justify-end w-full gap-4 pt-4 pb-3 pr-4 mt-4 border-t">
                                                            <button
                                                                type="submit"
                                                                className="w-1/5 h-8 rounded-md shadow-md border-1 bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte"
                                                                onClick={CallGridSave}
                                                            >
                                                                {GridModes.insert_mode ? 'Salvar' : 'Alterar'}
                                                            </button>
                                                        </div>
                                                    </GroupInput>
                                                )}
                                            </div>
                                            <div name="verificacao" className={`pb-3 ${values.statusverificacao === 'Concluído' ? 'bg-gray-300' : 'bg-white'}`}>
                                                <div className="w-full h-8 py-1 pl-2 mt-1 bg-amarelo-eq">Verificação</div>
                                                <TextInput name="verificacaoplano" disabled={!isResponsavel(values.resposavelplano)} label="Verificação do Plano" rows={3} />
                                                <GroupInput>
                                                    <DBSelectInput
                                                        name="resposavelplano"
                                                        label="Responsável"
                                                        disabled={!isResponsavel(values.resposavelplano)}
                                                        url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                        displaySelectField="nome"
                                                    />
                                                    <DateInput name="dataplano" label="Data" disabled={!isResponsavel(values.resposavelplano)} className="inline-block" />
                                                    <DateInput
                                                        name="prazoplano"
                                                        label="Prazo para Eficácia"
                                                        disabled={!isResponsavel(values.resposavelplano)}
                                                        className="inline-block"
                                                    />
                                                </GroupInput>
                                                <GroupInput>
                                                    <SelectInput
                                                        name="statusverificacao"
                                                        label="Status eficácia"
                                                        disabled={!isResponsavel(values.resposavelplano)}
                                                        className={'inline-block'}
                                                        listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                    />
                                                </GroupInput>
                                                {values.resposavelplano === System.property.user.crew_id && System.property.user.level === 1 && (
                                                    <GroupInput>
                                                        <div className="flex justify-end w-full gap-4 pt-4 pb-3 pr-4 mt-4 border-t">
                                                            <button
                                                                type="submit"
                                                                className="w-1/5 h-8 rounded-md shadow-md border-1 bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte"
                                                                onClick={CallGridSave}
                                                            >
                                                                {GridModes.insert_mode ? 'Salvar' : 'Alterar'}
                                                            </button>
                                                        </div>
                                                    </GroupInput>
                                                )}
                                            </div>
                                            <div name="eficacia">
                                                <div className="w-full h-8 py-1 pl-2 mt-1 bg-amarelo-eq">Eficácia</div>
                                                <TextInput name="eficaciaplano" label="Eficácia do Plano" disabled={!isResponsavel(values.resposaveleficacia)} rows={3} />
                                                <GroupInput>
                                                    <DBSelectInput
                                                        name="resposaveleficacia"
                                                        label="Responsável"
                                                        disabled={!isResponsavel(values.resposaveleficacia)}
                                                        url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                        displaySelectField="nome"
                                                    />
                                                    <DateInput name="dataeficacia" label="Data" disabled={!isResponsavel(values.resposaveleficacia)} className="inline-block" />
                                                </GroupInput>
                                                {(values.resposaveleficacia === System.property.user.crew_id || System.property.user.level === 1) && (
                                                    <GroupInput>
                                                        <div className="flex justify-end w-full gap-4 pt-4 pb-3 pr-4 mt-4 border-t">
                                                            <button
                                                                type="submit"
                                                                className="w-1/5 h-8 rounded-md shadow-md border-1 bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte"
                                                                onClick={CallGridSave}
                                                            >
                                                                {GridModes.insert_mode ? 'Salvar' : 'Alterar'}
                                                            </button>
                                                        </div>
                                                    </GroupInput>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return <div>Anexos</div>;
                            },
                            () => {
                                return <div>Espelho</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // 5W2H
                                return (
                                    <div name="acaoCorretiva" className={`bg-white pb-3`}>
                                        <div className="w-32 h-8 py-1 pl-2 mt-1 mb-2 border border-gray-600 rounded shadow bg-amarelo-eq">Número: {item.numero}</div>
                                        <Grid5W2H url={`/method5w2h/formA2SGI-${item._id}`} perms={'102001'} />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Diário de bordo
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/gridA2-' + item._id} perms={'102001'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Anexo
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={`/attachment/formA2SGI-${item._id}`} disableModal={true} perms={'102001'} />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Espelho
                                return (
                                    <div className={'m-2'}>
                                        <div className="pl-2 bg-amarelo-eq"> Espelho da Não conformidade em PDF.</div>
                                        <div className="text-white">
                                            <button className="flex px-4 py-2 mt-4 ml-4 font-bold rounded bg-amarelo-eq hover:bg-blue-700" onClick={() => EspelhoPdf(item)}>
                                                Gerar PDF
                                            </button>
                                        </div>
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/attachment/formA2SGI-${item._id}`);
                            await System.apiDelete(`/method5w2h/formA2SGI-${item._id}`);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
const FormA2SGINormal = () => {
    const System = useSystemContext();
    const gridRef = useRef(null);
    let _crew = [];
    System.apiGet('/crew').then((res) => {
        res.data.map((item) => {
            _crew[`${item._id}`] = item.nome;
            return true;
        });
    });
    const addFooter = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        const url = window.location.href; // Pega a URL corrente
        const hoje = new Date();
        const dia = hoje.getDate().toString().padStart(2, '0');
        const mes = (hoje.getMonth() + 1).toString().padStart(2, '0'); // Janeiro é 0!
        const ano = hoje.getFullYear();

        const dataFormatada = `${dia}/${mes}/${ano}`;

        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);

            const footerText = `Página ${i}/${pageCount} - URL: ${url} - Emitido em ${dataFormatada} por ${System.property.user?.name}`;
            const textSize = doc.getTextDimensions(footerText);
            const pageCenter = doc.internal.pageSize.width / 2;
            const xPosition = pageCenter - textSize.w / 2;

            // Defina a posição do rodapé aqui
            doc.text(footerText, xPosition, doc.internal.pageSize.height - 5);
        }
    };

    const EspelhoPdf = async (item) => {
        // Cria uma instância do objeto jsPDF
        const doc = new jsPDF();

        const response = await System.apiGet('/company/' + System.property.user.companyId);
        const company = response.data;
        const logo = await System.methods.apiGetImg(company.logotipo);

        // Adicionar o quadro com subdivisões
        const quadroWidth = doc.internal.pageSize.width - 20;
        const quadroTopo = 20;
        let top = 18;
        let lines = '';

        doc.setDrawColor(0);

        // Logo
        doc.addImage(logo, 'JPEG', doc.internal.pageSize.width - 55, 5, 30, 10);

        ///////////////////////////////////// Não conformidade
        doc.setFontSize(14);
        doc.text(`Relatório de não conformidade`, 12, 17);
        doc.line(10, 18, doc.internal.pageSize.width - 20, 18);
        doc.setFontSize(9);

        top = quadroTopo + 1;
        doc.text(`Número`, 12, (top += 5));
        doc.text(`Data`, 35, top);
        doc.text(`Emitente`, 70, top);
        doc.text(`Origem`, doc.internal.pageSize.width - 45, top);
        doc.text(item.numero || '---', 12, (top += 3));
        doc.text(dateToString(item.data) || '---', 35, top);
        doc.text(_crew[item.emitente] || '---', 70, top);
        doc.text(item.origem || '---', doc.internal.pageSize.width - 45, top);
        //
        doc.text(`Descrição resumida:`, 12, (top += 4));
        lines = doc.splitTextToSize(item.descricaoresumida, quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Cliente`, 12, (top += 5));
        doc.text(`Provedor externo`, 90, top);
        doc.text(`Processo`, doc.internal.pageSize.width - 45, top);
        doc.text(`${item.cliente || '---'}`, 12, (top += 3));
        doc.text(`${item.provedorexterno || '---'}`, 90, top);
        doc.text(`${item.processo || '---'}`, doc.internal.pageSize.width - 45, top);
        //
        doc.text(`Descrição completa:`, 12, (top += 5));
        lines = doc.splitTextToSize(item.descricao, quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //////////////////////////////// Disposicao

        doc.setFontSize(14);
        doc.text(`Disposição`, 12, (top += 15));
        doc.line(10, top + 1, doc.internal.pageSize.width - 20, top + 1);
        doc.setFontSize(9);
        //
        doc.text(`Disposição`, 12, (top += 7));
        doc.text(item.disposicao || '---', 12, (top += 3));
        //
        doc.text(`Detalhamento/Operação`, 12, (top += 4));
        lines = doc.splitTextToSize(item.detalhamento || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Responsável`, 12, (top += 4));
        doc.text(`Prazo`, doc.internal.pageSize.width - 60, top);
        doc.text(`Status`, doc.internal.pageSize.width - 40, top);
        doc.text(_crew[item.responsavel] || '---', 12, (top += 3));
        doc.text(dateToString(item.prazodisposicao) || '---', doc.internal.pageSize.width - 60, top);
        doc.text(item.statusdisposicao || '---', doc.internal.pageSize.width - 40, top);
        //
        doc.text(`Ação Corretiva:`, 12, (top += 4));
        doc.text(item.acaocorretiva || '---', 12, (top += 3));
        //////////////////////////////// Analise da causa
        doc.setFontSize(14);
        doc.text(`Análise da Causa`, 12, (top += 15));
        doc.line(10, top + 1, doc.internal.pageSize.width - 20, top + 1);
        doc.setFontSize(9);
        //
        doc.text(`Responsável`, 12, (top += 7));
        doc.text(`Prazo`, doc.internal.pageSize.width - 60, top);
        doc.text(`Status`, doc.internal.pageSize.width - 40, top);
        doc.text(_crew[item.resposavelanalise] || '---', 12, (top += 3));
        doc.text(dateToString(item.prazoanalise) || '', doc.internal.pageSize.width - 60, top);
        doc.text(item.statusanalise || '---', doc.internal.pageSize.width - 40, top);
        //
        doc.text(`Extensão da Ocorrência:`, 12, (top += 5));
        lines = doc.splitTextToSize(item.extensao || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`5 Porquês:`, 12, (top += 5));
        lines = doc.splitTextToSize(item.os5porques || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`6M's`, 12, (top += 5));
        //
        doc.text(`Máquina`, 15, (top += 3));
        lines = doc.splitTextToSize(item.maquina || '---', quadroWidth - 15);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Materiais`, 15, (top += 4));
        lines = doc.splitTextToSize(item.materiais || '---', quadroWidth - 15);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Mão de obra`, 15, (top += 4));
        lines = doc.splitTextToSize(item.maodeobra || '---', quadroWidth - 15);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Meio ambiente`, 15, (top += 4));
        lines = doc.splitTextToSize(item.meioambiente || '---', quadroWidth - 15);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Método`, 15, (top += 4));
        lines = doc.splitTextToSize(item.metodo || '---', quadroWidth - 15);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Medidas`, 15, (top += 4));
        lines = doc.splitTextToSize(item.medidas || '---', quadroWidth - 15);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Causa(s) Raiz`, 12, (top += 4));
        lines = doc.splitTextToSize(item.causaraiz || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Participantes da análise:`, 12, (top += 4));
        lines = doc.splitTextToSize(item.participanteanalise || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Data da análise`, 12, (top += 4));
        doc.text(dateToString(item.dataanalize) || '---', 12, (top += 3));
        //
        doc.text(`${item.alteramapeamento ? '[ X ]' : '[   ]'} Altera mapeamento de processos?`, 12, (top += 5));
        doc.text(`${item.alterariscos ? '[ X ]' : '[   ]'} Altera gestão de riscos e oportunidades?`, 12, (top += 4));
        doc.text(`${item.alteraaspectoimpacto ? '[ X ]' : '[   ]'} Altera gestão de aspectos e impactos ambientais?`, 12, (top += 4));
        doc.text(`${item.alteragestaoriscoasaude ? '[ X ]' : '[   ]'} Altera gestão de riscos à saúde e segurança ocupacional?`, 12, (top += 4));
        doc.text(`${item.alteracomunicacao ? '[ X ]' : '[   ]'} Altera gestão de comunicação?`, 12, (top += 4));
        doc.text(`${item.mudancasgq ? '[ X ]' : '[   ]'} Necessidade de mudança do SGQ?`, 12, (top += 4));
        /////////////////// Ação corretiva
        doc.addPage();
        doc.setFontSize(14);
        doc.text(`Ação corretiva`, 12, 16);
        doc.line(10, 18, doc.internal.pageSize.width - 20, 18);
        doc.setFontSize(9);
        top = 20;
        //
        doc.text(`Plano de Execução:`, 12, (top += 4));
        lines = doc.splitTextToSize(item.planoexecucao || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Responsável:`, 12, (top += 5));
        doc.text(`Prazo:`, doc.internal.pageSize.width - 80, top);
        doc.text(`Data:`, doc.internal.pageSize.width - 60, top);
        doc.text(`${_crew[item.responsavelacao] || '---'}`, 12, (top += 3));
        doc.text(dateToString(item.prazoanalise) || '---', doc.internal.pageSize.width - 80, top);
        doc.text(dateToString(item.dataacao) || '---', doc.internal.pageSize.width - 60, top);
        //
        doc.text(`Status ação corretiva:`, 12, (top += 4));
        doc.text(`${item.statusanalise || '---'}`, 12, (top += 3));
        ////////////////////// Verificação
        doc.setFontSize(14);
        doc.text(`Verificação`, 12, (top += 15));
        doc.line(10, top + 4, doc.internal.pageSize.width - 20, top + 3);
        doc.setFontSize(9);
        //
        doc.text(`Verificação do Plano:`, 12, (top += 10));
        lines = doc.splitTextToSize(item.verificacaoplano || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Responsável:`, 12, (top += 4));
        doc.text(`Data:`, doc.internal.pageSize.width - 80, top);
        doc.text(`Prazo para Eficácia:`, doc.internal.pageSize.width - 60, top);
        doc.text(`${_crew[item.resposavelplano] || '---'}`, 12, (top += 3));
        doc.text(dateToString(item.dataplano) || '---', doc.internal.pageSize.width - 80, top);
        doc.text(dateToString(item.prazoplano) || '---', doc.internal.pageSize.width - 60, top);
        //
        doc.text(`Status Eficácia:`, 12, (top += 4));
        doc.text(`${item.statusverificacao || '---'}`, 12, (top += 3));
        /////////////////// Eficácia
        doc.setFontSize(14);
        doc.text(`Eficácia`, 12, (top += 15));
        doc.line(10, top + 4, doc.internal.pageSize.width - 20, top + 3);
        doc.setFontSize(9);
        //
        doc.text(`Eficácia do Plano:`, 12, (top += 10));
        lines = doc.splitTextToSize(item.eficaciaplano || '---', quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += 3));
        });
        //
        doc.text(`Responsável:`, 12, (top += 4));
        doc.text(`Data:`, doc.internal.pageSize.width - 60, top);
        doc.text(`${_crew[item.resposaveleficacia] || '---'}`, 12, (top += 3));
        doc.text(dateToString(item.dataeficacia) || '---', doc.internal.pageSize.width - 60, top);
        //

        await System.apiGet(`/method5w2h/formA2SGI-${item._id}`).then((res) => {
            const items = res.data;
            if (items.length > 0) {
                doc.addPage();
                doc.setFontSize(14);
                doc.text(`5W2H`, 12, 16);
                doc.line(10, 18, doc.internal.pageSize.width - 20, 18);
                doc.setFontSize(9);
                top = 17;
                let i = 0;
                items.map((item) => {
                    i += 1;
                    if (i > 3) {
                        doc.addPage();
                        doc.setFontSize(14);
                        doc.text(`5W2H`, 12, 16);
                        doc.line(10, 18, doc.internal.pageSize.width - 20, 18);
                        doc.setFontSize(9);
                        top = 17;
                    }
                    doc.text(`O que?`, 12, (top += 5));
                    lines = doc.splitTextToSize(item.what || '---', quadroWidth - 10);
                    lines.forEach((line) => {
                        doc.text(line, 12, (top += 3));
                    });
                    //
                    doc.text(`Por que?`, 12, (top += 5));
                    lines = doc.splitTextToSize(item.why || '---', quadroWidth - 10);
                    lines.forEach((line) => {
                        doc.text(line, 12, (top += 3));
                    });
                    //
                    doc.text(`Quem?`, 12, (top += 5));
                    doc.text(`${_crew[item.who] || '---'}`, 12, (top += 3));
                    //
                    doc.text(`Quando?`, 12, (top += 5));
                    doc.text(`${dateToString(item.when) || '---'}`, 12, (top += 3));
                    //
                    doc.text(`Onde?`, 12, (top += 5));
                    lines = doc.splitTextToSize(item.where || '---', quadroWidth - 10);
                    lines.forEach((line) => {
                        doc.text(line, 12, (top += 3));
                    });
                    //
                    doc.text(`Como?`, 12, (top += 5));
                    lines = doc.splitTextToSize(item.how || '---', quadroWidth - 10);
                    lines.forEach((line) => {
                        doc.text(line, 12, (top += 3));
                    });
                    //
                    doc.text(`Quanto?`, 12, (top += 5));
                    lines = doc.splitTextToSize(item.howmuch || '---', quadroWidth - 10);
                    lines.forEach((line) => {
                        doc.text(line, 12, (top += 3));
                    });
                    //
                    doc.text(`Status?`, 12, (top += 5));
                    doc.text(`${item.status || '---'}`, 12, (top += 3));
                    //
                    doc.text(`Observações`, 12, (top += 5));
                    lines = doc.splitTextToSize(item.observacoes || '---', quadroWidth - 10);
                    lines.forEach((line) => {
                        doc.text(line, 12, (top += 3));
                    });
                    top += 6;
                    doc.line(10, top, doc.internal.pageSize.width - 20, top);
                    top += 6;
                    return true;
                });
            }
        });

        // Adicionar o rodapé
        addFooter(doc);

        // Salve o PDF
        doc.save(`NaoConformidade${item.numero ? '-' + item.numero : ''}.pdf`);
    };

    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/formA2"
                        perms={'102001'}
                        title={System.getMenuTitle(102001)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <Grid
                        url={'/griddocument/gridA2'}
                        perms={'102001'}
                        clearAutoEdit={true}
                        hideIndexColumn={true}
                        externalRef={gridRef}
                        GridTitle={'10.2 - Registro de Ocorrência'}
                        GridHeaders={[
                            { title: 'Número', field: 'numero', sort: true, className: 'w-1/12 text-left pl-1' },
                            { title: 'Data', field: 'data', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                            { title: 'Descrição', field: 'descricaoresumida', sort: false, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Origem', field: 'origem', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Processo', field: 'processo', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Cliente', field: 'cliente', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Provedor externo', field: 'provedorexterno', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Disposição', field: 'statusdisposicao', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                            { title: 'Ação corretiva', field: 'statusacao', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                            { title: 'Eficácia', field: 'statusverificacao', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [
                                data.numero,
                                dateToString(data.data),
                                data.descricaoresumida,
                                data.origem,
                                data.processo,
                                data.cliente,
                                data.provedorexterno,
                                data.statusdisposicao,
                                (data.acaocorretiva !== 'Não') ? data.statusacao : 'Não aplicável',
                                (data.acaocorretiva !== 'Não') ? data.statusverificacao : 'Não aplicável',
                            ];
                        }}
                        onBeforeInsertAction={(item) => {
                            const GridData = gridRef.current.methods.GridData();
                            let maiorNumero = GridData.reduce((maior, item) => {
                                const valor = parseInt(item.numero);
                                return valor > maior && Number.isInteger(valor) ? valor : maior;
                            }, 0);
                            maiorNumero += 1;
                            item.numero = maiorNumero.toString();

                            return item;
                        }}
                        onAfterLoadData={(data) => {
                            return data.map((item) => {
                                if (typeof item.statusdisposicao === 'undefined') item.statusdisposicao = '';
                                if (typeof item.statusacao === 'undefined') item.statusacao = '';
                                if (typeof item.statusverificacao === 'undefined') item.statusverificacao = '';

                                return item;
                            });
                        }}
                        validationSchema={yup.object().shape({
                            numero: yup.string(),
                            data: yup.date(),
                            emitente: yup.string(),
                            origem: yup.string(),
                            descricaoresumida: yup.string(),
                            cliente: yup.string(),
                            provedorexterno: yup.string(),
                            descricao: yup.string(),
                        })}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="numero" label="Número" className="w-1/6" />
                                        <DateInput name="data" label="Data" className="inline-block" />
                                        <DBSelectInput name="emitente" label="Emitente" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                        <SelectInput
                                            name="origem"
                                            label="Origem"
                                            className="inline-block"
                                            listOptions={[
                                                { value: 'Auditoria interna' },
                                                { value: 'Auditoria externa' },
                                                { value: 'Provedor externo' },
                                                { value: 'Cliente' },
                                                { value: 'Processo interno' },
                                                { value: 'Mudança' },
                                                { value: 'Incidente' },
                                                { value: 'Acidente' },
                                                { value: 'Outros' },
                                            ]}
                                        />
                                    </GroupInput>
                                    <Input name="descricaoresumida" label="Descrição resumida" className="w-full" />
                                    <GroupInput>
                                        <Input name="cliente" label="Cliente" />
                                        <Input name="provedorexterno" label="Provedor externo" />
                                        <Input name="processo" label="Processo" />
                                    </GroupInput>
                                    <TextInput name="descricao" label="Descrição completa" className="w-full" rows="5" />
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Disposição</div>;
                            },
                            () => {
                                return <div>Ação Corretiva</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Anexos</div>;
                            },
                            () => {
                                return <div>Espelho</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Disposição
                                return (
                                    <div>
                                        <FormInput
                                            document={`/griddocument/gridA2/${item._id}`}
                                            perms={'102001'}
                                            onAfterLoadData={(data) => {
                                                // Tratar registros antigos pois este campo mudou de status para acaocorretiva
                                                if (!('acaocorretiva' in data)) {
                                                    if (data.status === 'Ação corretiva') {
                                                        data.acaocorretiva = 'Sim';
                                                    }
                                                }
                                                return data;
                                            }}
                                            onRenderFields={(setFieldValue, values) => {
                                                return (
                                                    <div className="w-full">
                                                        <SelectInput
                                                            name="disposicao"
                                                            label="Disposição"
                                                            listOptions={[
                                                                { value: 'Rejeição' },
                                                                { value: 'Retrabalho' },
                                                                { value: 'Reclassificação' },
                                                                { value: 'Concessão' },
                                                                { value: 'Reposição' },
                                                                { value: 'Coleta e reposição' },
                                                                { value: 'Usar como está' },
                                                                { value: 'Outro' },
                                                            ]}
                                                        />
                                                        <TextInput name="detalhamento" label="Detalhamento/Operação" className="w-full" rows="5" />
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="responsavel"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                            <DateInput name="prazodisposicao" label="Prazo" className="w-1/6" />
                                                            <SelectInput
                                                                name="statusdisposicao"
                                                                label="Status"
                                                                className={'w-44'}
                                                                listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                            />
                                                        </GroupInput>
                                                        <SelectInput
                                                            name="acaocorretiva"
                                                            label="Ação Corretiva"
                                                            className={'w-3/6'}
                                                            listOptions={[{ value: 'Não' }, { value: 'Sim' }]}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            onAfterSave={() => {
                                                GridRefresh();
                                            }}
                                            validationSchema={yup.object().shape({
                                                disposicao: yup.string(),
                                                detalhamento: yup.string(),
                                                responsavel: yup.string(),
                                                status: yup.string(),
                                            })}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Ação Corretiva
                                return (
                                    <div>
                                        <FormInput
                                            document={`/griddocument/gridA2/${item._id}`}
                                            perms={'102001'}
                                            onRenderFields={(setFieldValue, values) => {
                                                return (
                                                    <div className="w-full">
                                                        <div className="pl-2 bg-amarelo-eq">Análise da Causa</div>
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="resposavelanalise"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                            <DateInput name="prazoanalise" label="Prazo" className="w-1/6" />
                                                            <SelectInput
                                                                name="statusanalise"
                                                                label="Status"
                                                                className={'w-44'}
                                                                listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                            />
                                                        </GroupInput>
                                                        <TextInput name="extensao" label="Extensão da Ocorrência" className="w-full" rows="2" />
                                                        <TextInput name="os5porques" label="5 Porquês" rows={5} />
                                                        <div className="mt-4 mb-2 ml-2">6M's</div>
                                                        <Input name="maquina" label="Máquina" />
                                                        <Input name="materiais" label="Materiais" />
                                                        <Input name="maodeobra" label="Mão de Obra" />
                                                        <Input name="meioambiente" label="Meio Ambiente" />
                                                        <Input name="metodo" label="Método" />
                                                        <Input name="medidas" label="Medidas" />
                                                        <TextInput name="causaraiz" label="Causa(s) Raiz" rows="2" />
                                                        <TextInput name="participanteanalise" label="Participantes da análise" rows="2" />
                                                        <DateInput name="dataanalize" label="Data da análise" />
                                                        <CheckBoxInput name="alteramapeamento" label="Altera mapeamento de processos?" />
                                                        <CheckBoxInput name="alterariscos" label="Altera gestão de riscos e oportunidades?" />
                                                        <CheckBoxInput name="alteraaspectoimpacto" label="Altera gestão de aspectos e impactos ambientais?" />
                                                        <CheckBoxInput name="alteragestaoriscoasaude" label="Altera gestão de riscos à saúde e segurança ocupacional?" />
                                                        <CheckBoxInput name="alteracomunicacao" label="Altera gestão de comunicação?" />
                                                        <CheckBoxInput name="mudancasgq" label="Necessidade de mudança do SGQ?" />
                                                        <div className="mt-2 ml-2 bg-amarelo-eq">Ação Corretiva</div>
                                                        <TextInput name="planoexecucao" label="Plano de Execução" className="w-full" rows={5} />
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="responsavelacao"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                            <DateInput name="prazoacao" label="Prazo" className="inline-block" />
                                                            <DateInput name="dataacao" label="Data" className="inline-block" />
                                                        </GroupInput>
                                                        <SelectInput
                                                            name="statusacao"
                                                            label="Status ação corretiva"
                                                            className={'inline-block'}
                                                            listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                        />
                                                        <div className="mt-2 ml-2 bg-amarelo-eq">Verificação</div>
                                                        <TextInput name="verificacaoplano" label="Verificação do Plano" rows={3} />
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="resposavelplano"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                            <DateInput name="dataplano" label="Data" className="inline-block" />
                                                            <DateInput name="prazoplano" label="Prazo para Eficácia" className="inline-block" />
                                                        </GroupInput>
                                                        <SelectInput
                                                            name="statusverificacao"
                                                            label="Status eficácia"
                                                            className={'inline-block'}
                                                            listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                        />
                                                        <div className="mt-2 ml-2 bg-amarelo-eq">Eficácia</div>
                                                        <TextInput name="eficaciaplano" label="Eficácia do Plano" rows={3} />
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="resposaveleficacia"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                            <DateInput name="dataeficacia" label="Data" className="inline-block" />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({
                                                extensao: yup.string(),
                                                investigacao: yup.string(),
                                                planoexecucao: yup.string(),
                                                alteramapeamento: yup.boolean(),
                                                alterariscos: yup.boolean(),
                                                alteracomunicacao: yup.boolean(),
                                                mudancasgq: yup.boolean(),
                                                responsavelacao: yup.string(),
                                                dataacao: yup.date(),
                                                verificacaooperacao: yup.string(),
                                                resposaveloperacao: yup.string(),
                                                dataoperacao: yup.date(),
                                                eficacaiaoperacao: yup.string(),
                                                resposaveleficacia: yup.string(),
                                                dataeficacia: yup.date(),
                                            })}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // 5W2H
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/formA2SGI-${item._id}`} perms={'102001'} />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Anexo
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={`/attachment/formA2SGI-${item._id}`} disableModal={true} perms={'102001'} />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Espelho
                                return (
                                    <div className={'m-2'}>
                                        <div className="pl-2 bg-amarelo-eq"> Espelho da Não conformidade em PDF.</div>
                                        <div className="text-white">
                                            <button className="flex px-4 py-2 mt-4 ml-4 font-bold rounded bg-amarelo-eq hover:bg-blue-700" onClick={() => EspelhoPdf(item)}>
                                                Gerar PDF
                                            </button>
                                        </div>
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/attachment/formA2SGI-${item._id}`);
                            await System.apiDelete(`/method5w2h/formA2SGI-${item._id}`);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const FormA3 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>5W2H</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/formA3"
                        perms="103"
                        title={System.getMenuTitle(103)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="5W2H">
                    <Grid5W2H
                        url="/method5w2h/formA3"
                        perms="103"
                        GridHeaders={[
                            { title: 'O que', field: 'what', sort: true, className: 'w-5/6 text-left pl-1' },
                            { title: 'Status', field: 'status', sort: true, className: 'w-2/6 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.what, data.status];
                        }}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/formA3'} perms="103" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
