import axios from 'axios';
import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
import fileDownload from 'js-file-download';
import Moment from 'moment';
import { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { FaFileDownload, FaSearchLocation, FaTrash } from 'react-icons/fa';
import InputMask from 'react-input-mask';
//import Select from 'react-select';
import { BiChevronDown } from "react-icons/bi";
import AsyncSelec from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Tabs } from 'react-tabs';
import { v4 as uuid } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
import { cn } from '../helper/utils';

function deepRecursiveParseData(data, field) {
    if (!field) return '';
    if (field.indexOf('.') > 0) {
        let fields = field.split('.');
        let firstField = fields.shift();
        return deepRecursiveParseData(data[firstField], fields.join('.'));
    } else {
        if (!data) return '';
        return data[field];
    }
}


export const DropboxDBInput = ({ displaySelectField, url, externalRef, onSelectedOption, ...rest }, ref) => {
    const System = useSystemContext();
    const [opcoes, setOpcoes] = useState([]);
    const [internalUrl, setInternalUrl] = useState(url ?? '');
    const SelectedOption = onSelectedOption ? onSelectedOption : () => null;

    useImperativeHandle(externalRef, () => ({
        changeUrl
    }));


    //const carregarOpcoes = async (inputValue) => {
    const carregarOpcoes = useCallback(async () => {
        try {
            const response = await System.apiGet(internalUrl);
            let opcoesFormatadas = response.data.map((item) => ({
                label: item[displaySelectField],
                value: item[displaySelectField],
                id: item._id,
            }));
            setOpcoes(opcoesFormatadas);
            return opcoesFormatadas;
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            return [];
        }
        // eslint-disable-next-line
    }, [internalUrl]);

    useEffect(() => {
        carregarOpcoes();
    }, [carregarOpcoes]);

    function changeUrl(newUrl) {
        setInternalUrl(newUrl);
        carregarOpcoes();
    }


    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full`, rest.className)}>
            <label htmlFor={rest.name} className="block">
                {rest.label}
            </label>
            <Field name={rest.name}>
                {({ field, form, meta }) => {
                    let selectedOption = { value: field.value, label: field.value };

                    return (
                        <AsyncCreatableSelect
                            style={{ display: 'block' }}
                            className={`block w-full rounded-md px-1 ${rest.disabled ? 'bg-gray-300' : ''}`}
                            value={selectedOption}
                            onChange={(newValue, actionMeta) => {
                                //console.log('onChange', newValue, actionMeta);
                                if (actionMeta.action === 'clear') {
                                    form.setFieldValue(rest.name, null);
                                    SelectedOption(null);
                                } else {
                                    form.setFieldValue(rest.name, newValue.value);
                                    SelectedOption(newValue);
                                }
                            }}
                            onCreateOption={(inputValue) => {
                                selectedOption = { value: inputValue, label: inputValue, id: null };
                                form.setFieldValue(rest.name, inputValue);
                            }}
                            placeholder="Selecione..."
                            createOptionPosition='first'
                            formatCreateLabel={(inputValue) => `Novo "${inputValue}"`}
                            isClearable
                            cacheOptions
                            allowCreateWhileLoading
                            defaultOptions={opcoes}
                            loadOptions={carregarOpcoes}
                        />
                    );
                }}
            </Field>
            <ErrorMessage className="text-xs text-red-400" name={rest.name} component="div" />
        </div>
    );
};

export const DropDownProcessosinput = ({ name, className, label = 'label' }) => {
    const System = useSystemContext();
    const [options, setOptions] = useState([]);
    const carregarOpcoes = useCallback(async () => {
        try {
            const response = await System.apiGet('/processwithsub');
            console.log('response', response.data);
            let opcoesFormatadas = response.data.map((item) => ({
                label: item['processo'],
                options: item.subprocessos.map((subitem) => ({
                    label: subitem['subprocesso'],
                    value: subitem._id,
                })),
            }));
            setOptions(opcoesFormatadas);
            return;
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            return [];
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        carregarOpcoes();
    }, [carregarOpcoes]);


    const [field, meta, helpers] = useField(name);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    let selectedOptions = field.value ?? [];
    const selectedCount = selectedOptions.length;

    const toggleOption = (option) => {
        if (selectedOptions.includes(option.value)) {
            selectedOptions = selectedOptions.filter((item) => item !== option.value);
        } else {
            selectedOptions.push(option.value);
        }
        helpers.setValue(selectedOptions);
    };

    const isSelected = (option) => selectedOptions.includes(option.value);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full relative`, className)} ref={dropdownRef}>
            <label htmlFor={name} className="block">
                {label}
            </label>

            <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="w-full px-2 py-2 text-left bg-white border border-gray-300 rounded-md shadow-sm "
            >
                <span className="block truncate">
                    {selectedCount > 0 ? `${selectedCount} Selecionados` : '<Lista Vazia>'}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 mt-4 pointer-events-none">
                    <BiChevronDown className="w-5 h-5 " />
                </span>
            </button>

            {isOpen && (
                <div className="absolute z-10 w-full mt-1 overflow-auto bg-white border border-gray-300 rounded-md shadow-lg max-h-60">
                    {options.map((group, groupIndex) => (
                        <div key={groupIndex}>
                            <div className="px-4 py-2 font-semibold bg-gray-100">{group.label}</div>
                            {group.options.map((option, optionIndex) => (
                                <label
                                    key={optionIndex}
                                    className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                                >
                                    <input
                                        type="checkbox"
                                        checked={isSelected(option)}
                                        onChange={() => toggleOption(option)}
                                        className="w-5 h-5 text-blue-600 form-checkbox"
                                    />
                                    <span className="ml-2">{option.label}</span>
                                </label>
                            ))}
                        </div>
                    ))}
                </div>
            )}

            {meta.touched && meta.error && (
                <div className="mt-1 text-sm text-red-500">{meta.error}</div>
            )}
        </div>
    );

}

export const Input = ({ type, name, label, error, className, defaultValue, pattern, ...rest }) => {
    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full`, className)}>
            <Field name={name}>
                {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                }) => (
                    <>
                        <label htmlFor={name} className="block">
                            {label}
                        </label>
                        <input
                            {...field}
                            name={name}
                            value={field.value || ''}
                            pattern={pattern}
                            type={type ?? 'text'}
                            className={`block w-full rounded-md border px-1 py-2 ${meta.touched && meta.error ? 'border-red-500' : ''} ${rest.disabled ? 'bg-gray-300' : ''}`}
                            {...rest}
                        />
                    </>
                )}
            </Field>
            <ErrorMessage className="text-xs text-red-400" name={name} component="div" />
        </div>
    );
};

export const InputCurrency = ({ name, label, error, className, defaultValue, ...rest }) => {
    const currencyMask = (value) => {
        // Remove qualquer caractere que não seja dígito
        const numericValue = value.replace(/\D/g, '');
        // Converte para número e divide por 100 para obter os centavos
        const numberValue = parseFloat(numericValue) / 100;
        // Formata como moeda
        return numberValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };

    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full`, className)}>
            <Field name={name}>
                {({
                    field,
                    form: { setFieldValue },
                    meta,
                }) => {
                    const handleChange = (event) => {
                        const { value } = event.target;
                        const formattedValue = currencyMask(value);
                        setFieldValue(name, formattedValue);
                    };

                    return (
                        <>
                            <label htmlFor={name} className="block">
                                {label}
                            </label>
                            <input
                                {...field}
                                name={name}
                                value={field.value || ''}
                                type="text"
                                onChange={handleChange}
                                className={`block w-full rounded-md border px-1 py-2 ${meta.touched && meta.error ? 'border-red-500' : ''
                                    } ${rest.disabled ? 'bg-gray-300' : ''}`}
                                {...rest}
                            />
                        </>
                    );
                }}
            </Field>
            <ErrorMessage className="text-xs text-red-400" name={name} component="div" />
        </div>
    );
};


export const AttachmentInput = ({
    type,
    name,
    label,
    className,
    callParentCommit = () => { },
    canDownload = true,
    canEdit = true,
    canInsert = true,
    canDelete = true,
    perms = null,
    ...rest
}) => {
    const System = useSystemContext();

    // permissoes
    const _canEdit = perms ? System.methods.getUserRule(`alterar${perms}`) : typeof canEdit === 'boolean' ? canEdit : true;

    const _canInsert = perms ? System.methods.getUserRule(`alterar${perms}`) : typeof canInsert === 'boolean' ? canInsert : true;

    const _canDelete = perms ? System.methods.getUserRule(`deletar${perms}`) : typeof canDelete === 'boolean' ? canDelete : true;

    async function _downloadFile(file) {
        const fileinfo = await System.apiGetFileInfo(file);
        const Ext = fileinfo.filename.split('.').pop();
        System.apiGetRawFile(file).then(async (response) => {
            if (response.status === 200) {
                fileDownload(response.data, file + '.' + Ext);
            }
        });
    }
    async function _deleteFile(file) {
        return await System.apiDelete(`/simpleupload/${file}`).then(async (response) => {
            if (response.status === 200) {
                return null;
            } else {
                console.log('Erro deletando anexo');
                return null;
            }
        });
    }
    async function _uploadFile(file, document) {
        let newForm = new FormData();
        newForm.append('arquivo', file);
        newForm.append('document', document);
        System.methods.Loading(true);
        return await System.apiPost('/simpleupload', newForm).then(async (response) => {
            System.methods.Loading(false);
            if (response.status === 200) {
                return response.data._id;
            }
            return null;
        });
    }
    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full`, className)}>
            <label htmlFor={name} className="block">
                {label}
            </label>
            <Field name={name}>
                {({ field, form: { touched, errors, setErrors, setFieldValue, initialValues }, meta }) => (
                    <div className="inline-flex">
                        {field.value && (
                            <div className="inline-flex ">
                                {canDownload && field.value.length === 24 && <FaFileDownload className="w-8 h-8 mt-2 mr-2 cursor-pointer" title="Baixar arquivo" onClick={() => _downloadFile(field.value)} />}
                                {_canDelete && field.value.length === 24 && (
                                    <FaTrash
                                        className="w-8 h-8 mt-2 mr-2 cursor-pointer"
                                        title="Excluir Arquivo Anexo"
                                        type="submit"
                                        onClick={async () => {
                                            await setFieldValue(name, await _deleteFile(field.value));
                                            callParentCommit();
                                        }}
                                    />
                                )}
                            </div>
                        )}
                        {_canEdit && _canInsert && (
                            <input
                                filename={field.value}
                                type="file"
                                className={`block w-full rounded-md border px-1 py-2 `}
                                {...rest}
                                onChange={async (e) => {
                                    setFieldValue(name, await _uploadFile(e.target.files[0], initialValues.document));
                                }}
                            />
                        )}
                    </div>
                )}
            </Field>
            <ErrorMessage className="text-xs text-red-400" name={name} component="div" />
        </div >
    );
};

export const DateInput = ({ type, name, label, error, className, defaultValue = '', information = '', ...rest }) => {
    return (
        <div className={`mt-1 pl-2 pr-2 ${className ? className : 'w-full'}`}>
            <Field name={name}>
                {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                }) => {
                    field.value = field.value ? Moment.utc(field.value).format('YYYY-MM-DD') : defaultValue;
                    return (
                        <>
                            <label htmlFor={name} className="block">
                                <div className='inline-flex'>
                                    {label}
                                    {information && <BiInfoCircle title={information} size={14} className='mt-1 ml-1 text-blue-700' />}
                                </div>
                            </label>
                            <input
                                {...field}
                                name={name}
                                type={type ?? 'date'}
                                className={`block w-full rounded-md border px-1 py-2 ${meta.touched && meta.error ? 'border-red-500' : ''} ${rest.disabled ? 'bg-gray-300' : ''}`}
                                {...rest}
                            />
                        </>
                    );
                }}
            </Field>
            <ErrorMessage className="text-xs text-red-400" name={name} component="div" />
        </div>
    );
};

export const TimeInput = ({ type, name, label, error, className, defaultValue, ...rest }) => {
    return (
        <div className={`mt-1 pl-2 pr-2 ${className ? className : 'w-full'}`}>
            <Field name={name}>
                {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                }) => {
                    return (
                        <>
                            <label htmlFor={name} className="block">
                                {label}
                            </label>
                            <input
                                name={name}
                                value={field.value}
                                type={type ?? 'time'}
                                className={`block w-full rounded-md border px-1 py-2 ${meta.touched && meta.error ? 'border-red-500' : ''}`}
                                onChange={(e) => {
                                    setFieldValue(name, e.target.value);
                                }}
                            />
                        </>
                    );
                }}
            </Field>
            <ErrorMessage className="text-xs text-red-400" name={name} component="div" />
        </div>
    );
};

export const GroupInput = (props) => {
    return (
        <div className="block">
            <div className="inline-flex w-full">{props.children}</div>
        </div>
    );
};

export const MaskInput = ({ type, name, label, className, mask, value, ...rest }) => {
    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full`, className)}>
            <label htmlFor={name} className="block">
                {label}
            </label>
            <Field name={name}>
                {({ field, form: { touched, errors }, meta }) => (
                    <InputMask
                        {...field}
                        mask={mask}
                        type={type ?? 'text'}
                        value={field.value || ''}
                        className={`block w-full rounded-md border px-1 py-2 ${meta.touched && meta.error ? 'border-red-500' : ''}`}
                        {...rest}
                    />
                )}
            </Field>
            <ErrorMessage className="text-xs text-red-400" name={name} component="div" />
        </div>
    );
};

export const TextInput = ({ type, name, label, className, rows = 10, cols = 160, mask, ...rest }) => {
    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full`, className)}>
            <label htmlFor={name} className="block">
                {label}
            </label>
            <Field name={name}>
                {({ field, form: { touched, errors }, meta }) => (
                    <>
                        <textarea
                            {...field}
                            className={`block w-full rounded-md border px-1 py-1 transition ${meta.touched && meta.error ? 'border-red-500' : ''} ${rest.disabled ? 'bg-gray-300' : ''
                                }`}
                            rows={rows}
                            cols={cols}
                            {...rest}
                        >
                            {field.value}
                        </textarea>
                    </>
                )}
            </Field>
            <ErrorMessage className="text-xs text-red-400" name={name} component="div" />
        </div>
    );
};

export const CepInput = ({ type, name, label, className, value, onReceivedCep, mask = '99999-999', ...rest }) => {
    async function BuscaCep(cep) {
        cep = cep.replace(/\D/g, '');
        if (cep.length === 8) {
            const url = `https://viacep.com.br/ws/${cep}/json/`;
            const response = await axios.get(url);
            if (!response.data?.erro) {
                if (onReceivedCep) onReceivedCep(response.data);
                return true;
            } else {
                return false;
            }
        }
    }
    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full`, className)}>
            <label htmlFor={name} className="block">
                {label}
            </label>
            <Field name={name}>
                {({ field, form: { touched, errors, setErrors }, meta }) => (
                    <div className="relative inline-flex">
                        <InputMask
                            {...field}
                            type={type ?? 'text'}
                            className={`block w-full rounded-md border px-1 py-2 ${meta.touched && meta.error ? 'border-red-500' : ''}`}
                            mask={mask}
                            value={field.value || ''}
                            {...rest}
                        />
                        <div className="absolute top-3 right-2">
                            <FaSearchLocation
                                className="text-gray-600 cursor-pointer hover:text-black"
                                title="Buscar dados do CEP"
                                onClick={async () => {
                                    let ret = await BuscaCep(field.value);
                                    if (ret === false) {
                                        let nk = {};
                                        nk[name] = 'CEP não encontrado';
                                        setErrors(nk);
                                    }
                                }}
                            />
                        </div>
                    </div>
                )}
            </Field>
            <ErrorMessage className="text-xs text-red-400" name={name} component="div" />
        </div>
    );
};

export const BasicInput = ({ type, name, label, className, value, ...rest }) => {
    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full`, className)}>
            <label htmlFor={name} className="block">
                {label}
            </label>
            <input type={type ?? 'text'} className={`block w-full rounded-md border px-1 py-2 `} value={value || ''} {...rest} />
            <ErrorMessage className="text-xs text-red-400" name={name} component="div" />
        </div>
    );
};

export const CheckBoxInput = ({ type, name, label, className, value, title, checkedValue = true, uncheckedValue = false, ...rest }) => {
    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full`, className)} title={title}>
            <div className="inline-flex">
                <Field name={name}>
                    {({ field, form: { touched, errors, setErrors, setFieldValue, initialValues }, meta }) => (
                        <div className="inline-flex">
                            <label htmlFor={name} className={`block`}>
                                <input
                                    value={value}
                                    type="checkbox"
                                    className={`mr-2 `}
                                    {...rest}
                                    checked={field.value === checkedValue}
                                    onChange={(e) => {
                                        console.log(name, e.target.checked ? checkedValue : uncheckedValue);
                                        setFieldValue(name, e.target.checked ? checkedValue : uncheckedValue);
                                        e.stopPropagation();
                                    }}
                                />
                                {label}
                            </label>
                            <ErrorMessage className="text-xs text-red-400" name={name} component="div" />
                        </div>
                    )}
                </Field>
            </div>
        </div>
    );
};

export const SelectInput = ({ type, name, label, className, listOptions = [], defaultValue = '', value, ...rest }) => {

    const options = typeof listOptions === 'function' ? listOptions() : listOptions;

    const internalListOptions = [{ value: '', option: 'Selecione...' }, ...options];
    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full`, className)}>
            <label htmlFor={name} className="block">
                {label}
            </label>
            <Field name={name}>
                {({ field, form, values, errors, setErrors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <select
                        {...field}
                        style={{ display: 'block' }}
                        className={`block w-full rounded-md border px-1 py-2  ${rest.disabled ? 'bg-gray-300' : ''}`}
                        {...rest}
                        value={field.value ?? defaultValue}
                    >
                        {internalListOptions.map((item, index) => (
                            <option key={index} value={item.value}>
                                {item.option ?? item.value}
                            </option>
                        ))}
                    </select>
                )}
            </Field>
            <ErrorMessage className="text-xs text-red-400" name={name} component="div" />
        </div>
    );
};
export const DBSelectInput = ({ displaySelectField, url, returnField = '_id', externalRef, className, ...rest }) => {
    const System = useSystemContext();
    const [listOptions, setListOptions] = useState([]);
    var internalUrl = url ?? '';
    useImperativeHandle(externalRef, () => ({
        changeUrl,
    }));

    const carregarOpcoes = useCallback(async () => {
        try {
            const response = await System.apiGet(internalUrl);
            let opcoesFormatadas = response.data.map((item) => ({
                option: deepRecursiveParseData(item, displaySelectField),
                value: deepRecursiveParseData(item, returnField),
                key: uuid(),
                fullDBitem: item,
            }));
            setListOptions(opcoesFormatadas);
            return opcoesFormatadas;
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            return [];
        }
        // eslint-disable-next-line
    }, [internalUrl, deepRecursiveParseData, displaySelectField, returnField]);

    useEffect(() => {
        carregarOpcoes();
    }, [carregarOpcoes]);

    function changeUrl(newUrl) {
        internalUrl = newUrl;
        carregarOpcoes();
    }

    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full`, className)}>
            <label htmlFor={rest.name} className="block">
                {rest.label}
            </label>
            <Field name={rest.name}>
                {({ field, form, meta }) => (
                    <>
                        <select
                            {...field}
                            style={{ display: 'block' }}
                            className={`block w-full rounded-md border  px-1 py-2 ${rest.disabled ? 'bg-gray-300' : ''} ${rest.name in form.errors ? 'border-red-500' : ''} `}
                            value={field.value?._id || field.value || ''}
                            {...rest}
                            onChange={(e) => {
                                form.setFieldValue(rest.name, e.target.value);
                                // formato do evento: onChange(option, value, fullDBItem)
                                if (rest.onChange) rest.onChange(e.target.options[e.target.selectedIndex].text, e.target.value, listOptions.find((item) => item.value === e.target.value).fullDBitem);
                            }}
                        >
                            <option value={null} key={uuid()}>
                                Selecione...
                            </option>
                            {listOptions.map((item) => (
                                <option key={item.key} value={item.value}>
                                    {item.option}
                                </option>
                            ))}
                        </select>
                        {rest.name in form.errors ? <div className="text-xs text-red-400">{form.errors[rest.name]}</div> : null}
                    </>
                )
                }
            </Field>
        </div>
    );
};

export const DBMultiSelectInput = ({ displaySelectField, url, returnField = '_id', externalRef, ...rest }) => {
    const System = useSystemContext();
    const [listOptions, setListOptions] = useState([]);
    var internalUrl = url ?? '';
    useImperativeHandle(externalRef, () => ({
        changeUrl,
    }));

    const carregarOpcoes = useCallback(async () => {
        try {
            const response = await System.apiGet(internalUrl);
            let opcoesFormatadas = response.data.map((item) => ({
                label: deepRecursiveParseData(item, displaySelectField),
                value: deepRecursiveParseData(item, returnField),
            }));
            setListOptions(opcoesFormatadas);
            return opcoesFormatadas;
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            return [];
        }
        // eslint-disable-next-line
    }, [internalUrl, deepRecursiveParseData, displaySelectField, returnField]);

    useEffect(() => {
        carregarOpcoes();
    }, [carregarOpcoes]);

    function changeUrl(newUrl) {
        internalUrl = newUrl;
        carregarOpcoes();
    }

    return (
        <div className={cn(`mt-1 pl-2 pr-2 w-full`, rest.className)}>
            <label htmlFor={rest.name} className="block">
                {rest.label}
            </label>
            <Field name={rest.name}>
                {({ field, form, meta }) => (
                    <AsyncSelec
                        {...field}
                        isMulti
                        placeholder="Selecione..."
                        style={{ display: 'block' }}
                        className={`block w-full rounded-md border basic-multi-select px-1 py-2 ${rest.disabled ? 'bg-gray-300' : ''}`}
                        value={field.value || ''}
                        {...rest}
                        onChange={(selectedOption) => {
                            form.setFieldValue(rest.name, selectedOption);
                        }}
                        options={listOptions}
                    />
                )
                }
            </Field>
            <ErrorMessage className="text-xs text-red-400" name={rest.name} component="div" />
        </div>
    );
};


export const FormInput = (props) => {
    const System = useSystemContext();

    const document = props.document;
    const initialValues = props.initialValues ?? {};
    const title = props.title ?? '';
    const validateOnBlur = props.validateOnBlur ?? true;
    const validateOnChange = props.validateOnChange ?? true;
    //const enableReinitialize = props.enableReinitialize ?? true;
    const validationSchema = props.validationSchema ?? {};
    //const onCancel = props.onCancel ?? {};
    const onPost = props.onPost ?? _onPost;
    const onPut = props.onPut ?? function _onPut(values) { };
    const onAfterSave = props.onAfterSave ?? _onAfterSave;
    const AfterLoadData = props.onAfterLoadData ?? _AfterLoadData;
    const onRenderFields = props.onRenderFields ?? {};
    const onPrepareSaveRecord = props.onPrepareSaveRecord ?? _onPrepareSaveRecord;

    const [iValues, setIValues] = useState(initialValues);
    const [onEdit, setOnEdit] = useState(false);
    const FormikRef = useRef(null);

    function _AfterLoadData(data) {
        return data;
    }

    const canEdit = System.isAutoEdit() ? true : props.perms ? System.methods.getUserRule(`alterar${props.perms}`) : typeof props.canEdit === 'boolean' ? props.canEdit : true;

    useEffect(() => {
        GetInicialValues();
        // eslint-disable-next-line
    }, []);
    function displayErrors(errors, touched) {
        if (touched) {
            let lst = Object.keys(errors);
            lst.map((key) => {
                if (errors[key] !== undefined) {
                    System.methods.notificationError(key + ' ' + errors[key]);
                }
                return null;
            });
        }
    }

    function _onAfterSave() { }
    function _onPost() { }

    function _onPrepareSaveRecord(values) {
        if (values.arquivo?.name) {
            let newForm = new FormData();
            for (let key in values) {
                newForm.append(key, values[key]);
            }
            return newForm;
        }
        return values;
    }

    function callCommit() {
        FormikRef.current.handleSubmit();
    }

    async function _commit(values) {
        if (!canEdit) {
            System.methods.notificationError('Usuário sem permissão para alterar dados..');
            return;
        }
        values = await onPrepareSaveRecord(values);
        let resultPost = await Post(values);
        return resultPost;
    }
    return (
        <div>
            <Formik
                key={uuid()}
                innerRef={FormikRef}
                initialValues={iValues}
                validateOnChange={validateOnChange}
                validateOnBlur={validateOnBlur}
                enableReinitialize={true} // {enableReinitialize}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(async () => {
                        await _commit(values);
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({ values, errors, setErrors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form>
                        {title && <div key={uuid()} className="w-full pl-2 font-semibold rounded-t bg-amarelo-eq">{title}</div>}
                        {onRenderFields(setFieldValue, values, callCommit)}
                        <div className="flex justify-end gap-4 pt-4 pb-3 pr-4 mt-4 border-t">
                            <button
                                type="submit"
                                className="w-1/4 h-8 text-black rounded-md shadow-md border-1 bg-botao-primario hover:bg-botao-primario-hover hover:text-gray-100"
                                onClick={() => {
                                    displayErrors(errors, touched);
                                }}
                            >
                                Salvar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
    async function GetInicialValues() {
        await System.apiGet(document).then(async (response) => {
            if (response?.status === 200) {
                let _data = await AfterLoadData(response.data);
                setOnEdit(true);
                setIValues(_data);
            } else {
                setOnEdit(false);
                setIValues(initialValues);
            }
        });
    }
    async function Post(values) {
        if (onEdit) {
            System.apiPut(document, values)
                .then((response) => {
                    if (response.status === 200) {
                        System.methods.notificationSuccess('Documento atualizado com sucesso!');
                        onPut(values);
                        onAfterSave(values);
                        GetInicialValues();
                    } else {
                        System.methods.notificationError('Erro ao atualizar documento!');
                    }
                    return response.status === 200;
                })
                .catch((error) => {
                    System.methods.notificationError('Erro ao atualizar documento!');
                    console.log(error);
                });
        } else {
            System.apiPost(document, values)
                .then((response) => {
                    if (response.status === 200) {
                        System.methods.notificationSuccess('Documento salvo com sucesso!');
                        onPost(values);
                        onAfterSave(values);
                    } else {
                        System.methods.notificationError('Erro ao salvar documento!');
                    }
                    return response.status === 200;
                })
                .catch((error) => {
                    System.methods.notificationError('Erro ao salvar documento!');
                    console.log(error);
                });
        }
    }
};

export const TabsInput = (props) => {
    const { isAutoEdit } = useSystemContext();
    const hash = window.location.hash.substring(1);
    const guiaDefault = parseInt(hash, 10) || 0;
    // Remove o hash da URL sem modificar a página atual
    if (window.history.pushState) {
        window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }
    return (
        <Tabs {...props} defaultIndex={isAutoEdit() ? 1 : guiaDefault}>
            {props.children}
        </Tabs>
    );
};
